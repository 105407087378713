
.first-footer {
    background-color: #f0f0f0;
  }
  
  .second-footer {
    background-color: #e0e0e0;
  }
  
  /* Add more styles here */
  .text-muted {
    --bs-text-opacity: 1;
    color: var(--bs-secondary-color) !important;
    text-align: center;
}

.contact-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.contact-info .content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon-img svg {
  fill: #0179FF; /* Adjust the fill color as needed */
  width: 24px;
  height: 24px;
}

.text h5 {
  margin: 0;
  font-size: 16px;
  color: #333; /* Adjust the text color as needed */
}

.text p {
  margin: 5px 0 0;
  font-size: 14px;
}

.text a {
  color: #0179FF; /* Adjust link color as needed */
  text-decoration: none;
}

.text a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .contact-info .content {
    justify-content: center;
    text-align: center;
  }

  .contact-info {
    justify-content: center;
  }
}
