.textshadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Adjust the values as needed */
}

.search-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 40vh; /* Use full viewport height to center content in the viewport */
  text-align: center; /* Centers the text horizontally */
  background-image: url('../../public/banners/banner.webp'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  padding: 20px;
  border-radius: 6px; /* This will apply rounded corners to the component */
  overflow: hidden; /* This ensures that the inner content also conforms to the border radius */
}

.background-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(62, 62, 62, 0.5); /* Dark overlay */
  z-index: 1;
}

.search-area {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Full width of the component */
  padding: 0 10px; /* Add padding for better spacing */
  box-sizing: border-box; /* Ensure padding does not affect the width */
}

.left-menu, .right-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center buttons horizontally */
  margin: 10px 0; /* Spacing above and below the menu */
}

.menu-item {
  background: none;
  border: none;
  margin: 5px;
  padding: 10px 20px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.3); /* Slight highlight on hover */
}

.search-bar {
  display: flex;
  justify-content: center; /* Center search bar horizontally */
  align-items: center; /* Center items vertically in the bar */
  width: 100%; /* Full width of the component */
  max-width: 600px; /* Maximum width for larger screens */
  margin: 10px 0; /* Spacing above and below the search bar */
}

.search-bar input {
  border: 2px solid #FF9900;
  border-radius: 5px;
  padding: 10px;
  margin-right: 10px;
  width: 100%; /* Full width of the container on mobile */
  max-width: 300px; /* Adjust as needed for larger screens */
  box-sizing: border-box; /* Ensure padding does not affect the width */
}

.search-bar .search-button {
  background-color: #FF9900;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

h2 {
  z-index: 2;
  color: white; /* Title color */
  margin-bottom: 20px; /* Spacing below the title */
}

/* Adjust the layout for smaller screens */
@media (max-width: 768px) {
  .search-area {
    padding: 10px; /* Add padding for better spacing on smaller screens */
  }

  .left-menu, .right-menu {
    justify-content: center;
    margin-bottom: 10px;
  }

  .search-bar {
    flex-direction: column;
    align-items: stretch; /* Stretch to full width */
  }

  .search-bar input {
    margin-right: 0;
    width: 100%; /* Full width on mobile */
  }

  .search-bar .search-button {
    width: 100%; /* Full width button on mobile */
  }
}
