/* privacy-policy.css */

/* Base font size */
body {
    font-size: 16px; /* Default size */
  }
  
  h1 {
    font-size: 2rem; /* More controlled size for desktop */
  }
  
  h2 {
    font-size: 1.75rem; /* Adjust as needed */
  }
  
  h3 {
    font-size: 1.5rem; /* Adjust as needed */
  }
  
  p {
    font-size: 1rem; /* Adjust as needed */
  }
  
  ul, ol {
    font-size: 1rem; /* Adjust as needed */
  }
  
  /* Responsive font sizes */
  @media (max-width: 768px) {
    body {
      font-size: 14px; /* Smaller screens */
    }
  
    h1 {
      font-size: 1.75rem; /* Slightly smaller on tablets */
    }
  
    h2 {
      font-size: 1.5rem; /* Adjust as needed */
    }
  
    h3 {
      font-size: 1.25rem; /* Adjust as needed */
    }
  
    p, ul, ol {
      font-size: 0.875rem; /* Adjust as needed */
    }
  }
  
  @media (max-width: 480px) {
    body {
      font-size: 12px; /* Smallest screens */
    }
  
    h1 {
      font-size: 1.5rem; /* Adjust as needed */
    }
  
    h2 {
      font-size: 1.25rem; /* Adjust as needed */
    }
  
    h3 {
      font-size: 1rem; /* Adjust as needed */
    }
  
    p, ul, ol {
      font-size: 0.75rem; /* Adjust as needed */
    }
  }
  