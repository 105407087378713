.header {
  width: 100%;
  text-align: center;
  margin-bottom: 30px; /* Add some space below the header */
  font-size: 24px; /* Adjust the font size as needed */
  color: #333; /* Adjust the color as needed */
}
.advCatGallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 10px;
   
  }
  
  .advCatItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .advCatImageWrapper {
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 8px; /* Space between image and text */
  }
  
  .advCatImage {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the area without stretching */
  }
  
  .advCatName {
    font-size: 12px;
    color: #333; /* Adjust text color as needed */
    font-weight: bold; /* If you want the text to be bold */
    margin-bottom: 16px; /* Space below text */
  }
  