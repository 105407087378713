.ad-background {
    position: relative;
    height: 100vh; /* الارتفاع الثابت للصورة الخلفية */
    background-size: cover;
    background-position: center;
}

 
 
 
.ad-content {
    
    background: rgba(255, 255, 255, 0.992);
    border-radius: 15px;
    padding: 20px;
    width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 20px auto; /* Adjusted for vertical spacing */
}


   
 

 
 

 
