.container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
}

.no-items-message {
    color: #ffa200; /* Reddish-orange color */
    text-align: center;
    margin-top: 20px;
    font-size: medium;
}

.header {
    font-size: medium;
}

.layout {
    display: grid;
    grid-template-columns: 1fr 3fr; /* Right column smaller, left column larger */
    gap: 20px; /* Space between columns */
}

.filter-column {
    padding: 20px;
    border: 1px solid #ddd; /* Light grey border */
    border-radius: 4px; /* Rounded corners */
    background-color: #f9f9f9; /* Slightly off-white background */
}

.search-input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.cards-column {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 cards per row */
    gap: 20px; /* Space between cards */
    width: 100%; /* Ensure full width */
}


.card {
    border: 1px solid #ddd; /* Light grey border */
    border-radius: 4px; /* Rounded corners */
    overflow: hidden; /* Ensure contents do not overflow */
    box-shadow: 0 2px 4px rgba(0,0,0,.1); /* Subtle shadow for depth */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center items horizontally */
    background-color: #fff; /* White background */
}

.card-image {
    width: 100%; /* Image takes the full width of the card */
    height: 180px !important; /* Fixed height for all images */
    object-fit: cover; /* Cover the area, possibly cropping the image */
}

.card-title, .card-description {
    padding: 10px 15px; /* Padding for some spacing inside the card */
}

.card-title {
    font-size: 20px; /* Larger font size for the title */
    margin: 10px 0; /* Margin top and bottom for spacing */
    color: #333; /* Dark grey/black color for text */
    font-weight: bold; /* Make title bold */
}

.card-description {
    font-size: 14px; /* Standard text size */
    color: #666; /* Lighter text color */
    text-align: justify; /* Justify description text */
    margin-bottom: 20px; /* Extra space at the bottom */
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .cards-column {
        grid-template-columns: repeat(2, 1fr); /* 2 cards per row on medium screens */
    }
}

@media (max-width: 768px) {
    .cards-column {
        grid-template-columns: 1fr; /* 1 card per row on smaller screens */
    }

    .layout {
        grid-template-columns: 1fr; /* Single column layout on smaller screens */
    }
}

.back-button {
    background-color: #FF9900;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
}

.back-button:hover {
    background-color: #0056b3;
}
/* Add styles for loading and error messages */
.loading-message, .error-message {
    text-align: center;
    font-size: 18px;
    color: #333;
    margin-top: 20px;
}

.error-message {
    color: #ff0000; /* Red color for errors */
}
/* Filter controls */
.filter-column {
  
}

.filter-group {
    margin-bottom: 15px;
}

.filter-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 1rem; /* Base font size */
}

.filter-select,
.filter-input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.price-range,
.rate-range {
  display: flex;
  gap: 10px;
}

.filter-column label {
  margin-top: 10px;
  display: block;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.cards-column {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  width: calc(33.333% - 20px);
}

.card-image {
  max-width: 100%;
  height: auto;
}

.no-items-message {
  text-align: center;
  color: #999;
}

.back-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #0056b3;
}


/* Responsive adjustments */
@media (max-width: 1024px) {
    .filter-group label {
        font-size: 0.9rem; /* Slightly smaller font size */
    }

    .filter-select, .search-input {
        font-size: 0.9rem; /* Slightly smaller font size */
    }
}

@media (max-width: 768px) {
    .filter-group label {
        font-size: 0.8rem; /* Smaller font size for mobile screens */
    }

    .filter-select, .search-input {
        font-size: 0.8rem; /* Smaller font size for mobile screens */
        padding: 8px; /* Adjust padding for smaller screens */
    }
}

@media (max-width: 480px) {
    .filter-group label {
        font-size: 0.7rem; /* Further reduced font size for very small screens */
    }

    .filter-select, .search-input {
        font-size: 0.7rem; /* Further reduced font size for very small screens */
        padding: 6px; /* Further reduced padding */
    }
}
