/* Ensure higher specificity for your custom styles */
.login-modal {
    direction: rtl; /* Apply RTL layout to the whole modal */
  }
  
  /* Optional: Align text to the right for the header and body content */
  .login-modal .modal-header,
  .login-modal .modal-body {
    text-align: right;
  }
  
  /* Correct the close button alignment */
  .login-modal .modal-header .close {
    float: left; /* Adjust the close button position for RTL */
  }
.login-modal .modal-body .social-btn {
    direction: rtl;
    display: block !important; /* Force the block display */
    width: 90% !important; /* Adjust width as needed and ensure it is centered */
    margin: 10px auto !important; /* Center the button */
    padding: 10px !important; /* Padding for the buttons */
    border: none !important;
    border-radius: 5px !important; /* Rounded corners */
    color: white !important;
    cursor: pointer;
    font-weight: bold; /* Optional: if you want the font to be bold */
    text-align: right;
    font-size: medium;
  }
  
  /* Specific social button colors */
  .login-modal .modal-body .social-btn.facebook {
    background-color: #3b5998 !important; /* Facebook blue */
  }
  
  .login-modal .modal-body .social-btn.google {
    background-color: #db4437 !important; /* Google red */
  }
  
  .login-modal .modal-body .social-btn.apple {
    background-color: #a6b1b7 !important; /* Apple grey */
  }
  
  /* Adjust the Font Awesome icon styles if needed */
  .login-modal .modal-body .social-icon {
    margin-right: 15px;
    margin-left: 10px;
  }
  
  .login-modal .modal-footer {
   
    font-size: small;
  }
  
  .login-modal .modal-footer a {
    color: #565656; /* Example link color */
    text-decoration: underline; /* Underline to denote clickable links */
    font-size: small;
  }