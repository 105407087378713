/* Main Header Styles */
.header {
  background-color: #fff;
  padding: 5px 0;
  border-bottom: 1px solid #e7e7e7;
}

.header-logo {
  max-height: 50px;
  width: auto;
}

.nav-item {
  color: #555;
  margin: 0 10px;
  display: flex;
  align-items: center;
  flex-direction: column; /* Icons above text for desktop view */
  text-align: center; /* Center the text below icons */
}

.nav-item span {
  margin-top: 5px; /* Space between icon and text */
}

.nav-item:hover {
  color: #333;
}

/* Secondary Navigation Styles */
.secondary-nav {
  display: flex;
  justify-content: center;
  background-color: #f8f9fa;
  padding: 10px 0;
  border-top: 1px solid #e7e7e7;
  flex-wrap: nowrap; /* Prevent wrapping for horizontal scroll */
  overflow-x: auto; /* Allow horizontal scrolling */
  font-weight: bold; /* Make text bold */
}

.secondary-nav-item {
  color: #555;
  margin: 0 15px;
  text-decoration: none;
  font-size: 14px;
  white-space: nowrap; /* Prevent text from wrapping */
}

.secondary-nav-item:hover {
  color: #333;
  text-decoration: underline;
}

/* Button Styles */
.register-button {
  background-color: #FF9900;
  color: #fff;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  margin-right: 4px;
}

.register-button:hover {
  background-color: #cc7a00;
}

.login-button {
  background-color: #FF9900;
  color: #fff;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  margin-right: 4px;
}

.login-button:hover {
  background-color: #0056b3;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .header-logo {
    max-height: 40px;
    width: auto;
    margin-bottom: 10px;
  }

  .nav-item {
    margin: 0 5px;
    flex-direction: row; /* Icons and text side by side for mobile view */
    text-align: left; /* Align text to left in mobile view */
  }

  .nav-item span {
    margin-right: 10px; /* Space between icon and text in mobile view */
    margin-top: 0; /* Reset top margin to align with icon */
  }

  .secondary-nav {
    overflow-x: scroll; /* Enable horizontal scrolling on mobile */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }

  .register-button {
    background-color: #FF9900;
    color: #fff;
    border: none;
    padding: 10px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    margin-right: 4px;
  }

  .register-button:hover {
    background-color: #FF9900;
    border-color: #FF9900;
  }

  .login-prompt {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .register-button {
    margin: 10px;
  }

  .navbar-collapse {
    text-align: center; /* Center content when the menu is expanded */
  }

  .navbar-toggler {
    border: none;
    background-color: transparent;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-collapse {
    display: flex;
    flex-direction: column;
  }
}
