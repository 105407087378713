/* AdsSlider.css */
.swiper {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px; /* Adjust based on your preference */
    height: 100%; /* Adjust if you want fixed height slides */
  }
  
  .ad-card img {
    width: 100%; /* Ensure the image spans the full width of its container */
    height: 150px; /* Fix the height of the image */
    object-fit: cover; /* Cover the container without losing the aspect ratio */
    object-position: center; /* Center the image within the container */
  }
  
  .ad-card {
    display: flex;
    flex-direction: column;
    border-radius: 0.50rem; /* Bootstrap default border-radius */
   /* border: 1px solid rgba(0,0,0,.125);  Bootstrap default border */
    /* box-shadow: 0 4px 6px rgba(0,0,0,.1); /* A subtle shadow effect */
    overflow: hidden; /* Ensures the content respects the border-radius */
height: 280px;
}
.ad-card:hover {
  box-shadow: 0 4px 6px rgba(0,0,0,.1); /* Shadow effect on hover */
}
  /* Title and description styles */
  .ad-card h5, .ad-card p, .ad-card small {
    padding: 0 10px; /* Adds some padding around the text */
  }
  
  h5{
    font-weight: 600 !important;
    color: darkslategray;
  }
  .no-underline {
    text-decoration: none;
  }

  /* Updated date text style */
  .ad-card small {
    display: block; /* Ensure it takes its own line */
    margin-top: 10px; /* Add some space above the date */
    color: #666; /* Dim the text color for the date */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .ad-card {
  
      margin: 0 4px; /* Adjust margin for smaller screens */
    }
  }
  
  .ad-card h5 {
    margin: 5px 0;
    font-size: 1em; /* Adjust based on your design */
    font-weight: bold;
  }
  
  .ad-card p {
    font-size: 0.8em;
    margin-bottom: 5px;
  }
  
  
/* Responsive adjustments */
@media (max-width: 768px) {
    .ad-card {
      margin: 0 4px;
    }
  }

 

  .user-avatar {
    height: 32px !important; /* Adjusted to fit the smaller avatar */
    width: 32px !important;
    object-fit: cover;
    border-radius: 50%; /* Keeps the avatar round */
    margin-right: 10px 
}

.card-header {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust the space between the avatar and the title if needed */
    margin-top: 10px; /* Space between the image and the header */
}

/* Ensure the avatar container is adjusted accordingly */
.v-avatar {
    height: 16px !important; /* Adjusted to fit the smaller avatar */
    width: 16px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ad-image {
    width: 100%;
    object-fit: cover; /* Adjust based on your design */
    border-radius: 0.50rem;
}
.end {
    display: flex;
    justify-content: space-between; /* This will push items to the start and end of the container */
    align-items: center;
    text-align: start; /* Aligns text to the start, useful for RTL languages */
  }

.location{
    margin-left: 50px;
}
  .navigation-icon {
    width: 24px; /* Adjust based on your icon size */
    height: 24px;
    margin-right: 10px;
}

.navigation-icon img {
    width: 100%;
    height: auto;
}

.ad-footer {
    display: flex;
    align-items: center;
    margin-top: 10px; /* Adjust spacing as needed */
    
}

.my-icon-color {
    color: rgb(119, 119, 119);
  }

  .category {
    border: 0.5px;
    background-color: #f1f1f1;
    border-radius: 0.50rem;
    margin-top: 1px;
    overflow: hidden; /* Ensures the content respects the border-radius */
}

.slider{
  background-size: cover;
  border-radius: 6px;
  min-height: 257px;
}

.ad-link {
  color: inherit; /* This makes the link color the same as the surrounding text */
  text-decoration: none; /* Removes underline from links */
}
  