.search-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .search-container .input-group {
    margin-right: 0.5rem;
  }
  
  .search-container .btn-outline-secondary {
    border-color: #ced4da;
  }
  
  .search-container .dropdown-toggle::after {
    margin-left: 0.25rem;
  }
  
  .search-container .dropdown-menu {
    min-width: 10rem;
  }
  
  .search-container .input-group-text {
    background-color: #f8f9fa;
    border-color: #ced4da;
  }
  
  .search-container .form-control {
    border-left: 0;
  }
  
  .search-container .btn-link {
    color: #006400;
    text-decoration: underline;
    padding: 0;
  }
  
  .search-container .btn-link:hover {
    color: #004d00;
    text-decoration: underline;
  }
  .search-input {
    width: 100%;
  }
    