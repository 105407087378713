@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;700&display=swap');
body {
  font-family: 'Tajawal', sans-serif;
}
h1, h2, h3 {
  font-family: 'Tajawal', sans-serif;
  font-weight: 600;
}

.header-divider {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  margin-top: 0; /* Adjust as needed */
}

html, body, #root {
  height: 100%;
  margin: 0;
}

 

.app-container {
  display: grid;
  grid-template-rows: auto 1fr auto; /* Header size, dynamic content, footer size */
  min-height: 50vh;
  direction: rtl;
}
.page-content {
  padding-top: 250px; /* Adjust based on the height of your header */
}
/* Assuming .content fills the space between header (if any) and footer */
.content {
  flex: 1; /* This makes the content area flexible and pushes the footer down */
}

/* Style your footer as needed */
footer {
  /* Ensure it doesn't grow or shrink, keeping it at the bottom */
  flex-shrink: 0;
}